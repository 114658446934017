.usernav {
  display: flex;
  flex-direction: column;
  row-gap: 1.25em;

  &__list {
    @include lreset;
    display: flex;
    flex-direction: column;
    row-gap: 0.75em;

    &--closed {
      position: relative;
      justify-content: center;
      min-height: 3.625em;
      padding: 0.75em;
      border-radius: 0.25em;
      border: 1px dashed $grey-c0;

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: 12px;
        transform: translateY(-50%);
        width: 34px;
        height: 34px;
        border-radius: 50%;
        border: 1px solid $grey-c0;
        background: url("@public/images/lock.svg") 50% no-repeat; }

      .usernav {
        &__item {
          &-link {
            grid-template-columns: 1fr;
            color: $grey-c0;

            &--soon {
              padding: 0.5em 0;
              width: 100%;
              color: $grey-88;
              text-align: center;
              border-radius: 4px;
              border: 1px solid $grey-88; }

            &--closed {
              width: 2.125rem;
              height: 2.125rem;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 50%;
              border: 1px solid $grey-c0; } } } } } }

  &__item {
    &--active {
      a {
        font-weight: 700; } }

    &-link {
      @include fs14;
      display: inline-flex;
      column-gap: 0.5714em;
      align-items: center;
      color: $dark-grey;

      &:focus {
        outline: none; } }

    &-counter {
      @include fs11;
      display: flex;
      align-items: center;
      height: 1.7273em;
      padding: 0 0.3636em;
      color: $white;
      font-weight: 800;
      border: 1px solid $grey-c0;
      border-radius: 0.25rem;

      &--active {
        color: $white;
        border-color: $color-red;
        background-color: $color-red; } } } }
