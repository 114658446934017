.tariff {
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 1.25em;
  padding: 1.875em 1.125em;
  border: 2px solid $grey-e5;
  border-radius: 0.75em;
  background: $white;

  @include media-tablet {
    row-gap: 2em;
    padding: 2.375em; }

  &__tag {
    @include fs11;
    position: absolute;
    top: 1.875rem;
    right: 1.125rem;
    display: none;
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
    background: $red;
    color: $white;

    @include media-tablet {
      top: 2.375rem;
      right: 2.375rem; }

    @include media-desktop-1600 {
      display: block; } }

  &__header {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 1em; }

  &__title {
    @include h32;

    @include media-tablet {
      @include h36; } }

  &__price {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 0.25em;

    @include media-tablet {
      gap: 0.5em; } }

  &__old-price {
    @include fs18;
    display: flex;
    align-items: center;
    column-gap: 0.375rem;
    color: $grey-88;

    span {
      font-size: 1.5rem;
      font-weight: 700;
      line-height: 1.25;
      color: $red;

      @include media-tablet {
        font-size: 2.25rem; } } }

  &__new-price {
    @include fs14;
    padding: 0.25rem 0.625rem;
    border-radius: 0.75rem;
    background: $grey-f0;
    color: $grey-88;

    @include media-tablet {
      @include fs16; } }

  &__divider {
    width: 100%;
    height: 1px;
    background: $grey-e5; }

  &__description {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.25em;

    &-link {} }

  &__features {
    @include lreset;
    display: flex;
    flex-direction: column;
    gap: 1.25em;

    &-item {
      @include fs16;
      display: flex;
      column-gap: 0.75rem;

      @include media-tablet {
        @include fs18; }

      &::before {
        content: '';
        flex: 0 0 1.125rem;
        width: 1.125rem;
        height: 1.125rem;
        background: url("../images/icon-check.svg") 50% / contain no-repeat; } }

    &-item-missing {

      text-decoration: line-through;
      color: #C0C0C0;

      &::before {
        content: '';
        flex: 0 0 1.125rem;
        width: 1.125rem;
        height: 1.125rem;
        background: url("../images/icon-uncheck.svg") 50% / contain no-repeat; } } }



  &__btn {
    width: 100%;
    margin: auto auto 0;
    text-align: center;
    font-weight: 700; } }
