$color-gray: #e4e4e4;
$color-orange: #f8a332;
$color-green: #4ecd00;
$color-red: #ff0011;
$color-lightgray: #f6f6f6;
$color-dark: #333333;
$color-black: #000222;
$color-yellow: #ffd600;
$color-yellow-new: #ffee00;
$color-yellow-2: #eedd00;

$white: #ffffff;
$red: #ff0011;
$red2: #ee0000;
$dark-grey: #191919;
$light-grey: #f8f8f8;
$grey: #8f8f8f;
$grey-22: #222222;
$grey-80: #808080;
$grey-82: #828282;
$grey-88: #888888;
$grey-89: #888999;
$grey-92: #929292;
$grey-c0: #c0c0c0;
$grey-c4: #c4c4c4;
$grey-d5: #d5d5d5;
$grey-e5: #e5e5e5;
$grey-ee: #eeeeee;
$grey-f0: #f0f0f0;
$grey-f5: #f5f5f5;
$grey-32: #323232;
$lightblue: #55ccff;
$green: #006633;
$yellow: #f3f71d;
$yellow-calendar: #ffcc44;
$green-calendar: #66cc99;
$green-footer: #8ed101;
