.review {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.25em;
  border-radius: 0.75em;
  background: $white;

  @include media-tablet {
    gap: 2rem; }

  @include media-desktop-1280 {
    gap: 1.5rem; }

  @include media-desktop-1440 {
    flex-direction: row; }

  &__text-wrapper {
    display: flex;
    gap: 1.5rem;
    flex-direction: column; }

  &__user {
    display: flex;
    flex-direction: column;
    gap: 1.25em;
    width: 100%;

    @include media-tablet {
      flex-direction: row;
      align-items: center;
      gap: 2em; }

    @include media-desktop-1280 {
      align-items: flex-start;
      flex-direction: column;
      gap: 2.5rem; }

    &-photo {
      flex: 0 0 6.8125em;
      width: 108px;
      height: 108px;
      border-radius: 50%;
      overflow: hidden; }

    &-info {
      display: flex;
      flex-direction: column;
      gap: 0.25rem; }

    &-name {
      @include h24;
      font-weight: 800; }

    &-position {
      @include fs16;
      color: $grey-88; } }

  &__text {
    @include fs16;
    line-height: 22.4px;
    white-space: pre-line;

    @include media-tablet {
      @include fs18;
      line-height: 25.2px; } } }
