.header--index {
  display: block;
  height: auto;
  z-index: 0;

  .header {
    &__main {
      background: $red;

      @include media-tablet-h {
        display: grid;
        grid-template-columns: 1fr 34.875em;
        min-height: 700px; }

      @include media-desktop {
        grid-template-columns: 1fr 40em;
        grid-template-columns: 1fr 1fr; }

      &-left {
        position: relative;
        display: none;
        background-image: url("../images/header/image.jpg");
        background-size: 100%;
        background-position: center 70%;

        @include media-tablet-h {
          display: block;
          height: 100%;
          overflow: hidden; } }

      &-greetings {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 6.745875em;
        height: 6.7499375em;
        z-index: 3;

        @include media-desktop {
          width: 8.309em;
          height: 8.3139375em; }

        @include media-desktop-1440 {
          width: 9.748625em;
          height: 9.7544375em; }

        @include media-desktop-1920 {
          width: 11.3125em;
          height: 11.3125em; } }

      &-welcome {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 20.5em;
        height: 20.5em;
        z-index: 3;

        @include media-desktop {
          width: 25.25em;
          height: 25.25em; }

        @include media-desktop-1440 {
          width: 29.625em;
          height: 29.625em; }

        @include media-desktop-1920 {
          width: 35.1023125em;
          height: 35.0779375em; }

        img {
          animation: 40s linear infinite rotate; } }

      &-right {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 2.3125em 1.25em 2.5625em;
        background: $white;
        color: $dark-grey;

        @include media-tablet {
          padding: 2.625em 6.5em; }

        @include media-tablet-h {
          padding: 4em 4.625em;
 }          //border-radius: 0 0 0 1.25em

        @include media-desktop {
          padding: 4.875em 2.75em; }

        @include media-desktop-1440 {
          padding: 4.25em 5.75em; }

        @include media-desktop-1600 {
          padding: 6.375em 5.625em; }

        @include media-desktop-1920 {
          padding: 5.9375em 6.0625em; } }

      &-date {
        @include h20;
        margin-bottom: 1.4375rem;
        font-weight: 400;

        @include media-tablet {
          font-size: 1.75em;
          margin-bottom: 2rem; }

        @include media-desktop {
          font-size: 2.125em;
          margin-bottom: 2.5rem; }

        @include media-desktop-1920 {
          font-size: 2em;
          margin-bottom: 4.375rem; } }

      &-title {
        @include h36;
        margin-bottom: 0.875rem;
        color: $red;
        text-transform: uppercase;

        @include media-tablet {
          @include h64;
          margin-bottom: 1.125rem; }

        @include media-tablet-h {
          @include h48; }

        @include media-desktop-1440 {
          font-size: 4.1875em; }

        @include media-desktop-1920 {
          @include h96;
          max-width: 47.875rem;
          margin-bottom: 0.75rem; } }

      &-desc {
        @include fs26;
        max-width: 34.875rem;
        margin-bottom: 1.4375rem;
        font-weight: 700;

        @include media-tablet {
          @include fs20;
          margin-bottom: 2rem; }

        @include media-desktop {
          @include fs24;
          margin-bottom: 2.5rem; }

        @include media-desktop-1920 {
          max-width: 47.875rem;
          margin-bottom: 4.375rem;
          font-size: 2em; } }

      &-bottom {
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 0.625em;

        .btn {
          align-self: flex-start; } }

      &-archive {
        @include fs16;
        color: $grey-88;
        max-width: 47rem;

        @include media-tablet {
          @include fs18; } } } } }

.header--netw {
  .header {
    &__main {
      margin-top: 2.5em;
      padding-top: 1.5em;
      padding-bottom: 1.5em;
      border-bottom-left-radius: 0.5em;
      border-bottom-right-radius: 0.5em;

      @include media-tablet {
        margin-top: 3.625em;
        padding-top: 2.5em;
        padding-bottom: 2.5em;
        border-bottom-left-radius: 1em;
        border-bottom-right-radius: 1em; }

      @include media-desktop {
        padding-top: 5em;
        padding-bottom: 5em; } }

    &__title {
      @include reset;
      @include h24;

      @include media-tablet {
        @include h36; }

      @include media-desktop {
        @include h64; } } } }

@keyframes rotate {
  from {
    transform: rotate(0deg); }

  to {
    transform: rotate(360deg); } }
