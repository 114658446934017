.about-netw {
  background: $grey-32;

  &__logo {
    pointer-events: none;
    width: 265.76px;
    height: auto; }

  &__main {
    position: relative;

    @include media-tablet-h {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100%; } }

  &__cont {
    display: flex;
    flex-direction: column;
    row-gap: 64px;
    padding-top: 54px;
    padding-bottom: 32px;
    color: #fff;

    @include media-tablet {
      align-items: flex-start;
      padding-top: 64px; }

    @include media-tablet-h {
      box-sizing: content-box;
      width: 437px;
      margin-left: 0;
      padding-top: 80px;
      padding-bottom: 80px;
      padding-right: 0;
      padding-left: 40px; }

    @include media-desktop {
      width: 655px;
      gap: 80px;
      padding-left: 80px; }

    @include media-desktop-1440 {
      width: 738px; }

    @include media-desktop-1600;

    @include media-desktop-1920 {
      margin-left: auto;
      margin-right: 48%;
      width: 802px; } }


  &__title {
    @include h24;
    text-transform: uppercase;

    @include media-tablet-h {
      @include fs18; }

    @include media-desktop {
      @include h24; }

    @include media-desktop-1440 {
      @include h36; }

    @include media-desktop-1600 {
      width: 46.5rem; }

    span {
      display: block;
      color: $red; } }

  &__stats {
    @include media-tablet {
      display: flex;
      gap: 2.5em; }

    @include media-tablet-h {
      flex-direction: column;
      gap: 1.25em; }

    @include media-desktop {
      flex-direction: row;
      gap: 2em; }

    @include media-desktop-1600 {
      width: 50%; }

    &-item {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 0.375em;

      @include media-tablet {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 0; }

      @include media-desktop {
        width: auto; }

      &:not(:last-child) {
        margin-bottom: 24px;
        padding-bottom: 24px;
        border-bottom: 1px solid $grey-92;

        @include media-tablet {
          margin-bottom: 0;
          padding-right: 2.5em;
          padding-bottom: 0;
          border-right: 1px solid $grey-92;
          border-bottom: none; }

        @include media-tablet-h {
          padding-right: 0;
          padding-bottom: 1.25em;
          border-right: none;
          border-bottom: 1px solid $grey-92; }

        @include media-desktop {
          padding-right: 2em;
          padding-bottom: 0;
          border-right: 1px solid $grey-92;
          border-bottom: 0; } } }

    &-number {
      @include h24;
      display: flex;
      align-items: center;
      column-gap: 0.75rem;

      @include media-desktop-1440 {
        @include h32; }

      span {
        padding: 0 0.3125rem;
        font-size: 0.875rem;
        line-height: 1.3;
        font-weight: 800;
        color: #888899;
        border: 1px solid #888899;
        border-radius: 2.4375rem;

        @include media-tablet {
          margin-top: -1em;
          padding: 0 0.40625rem;
          font-size: 1rem; } }

      br {
        display: none;

        @include media-tablet {
          display: block; }

        @include media-tablet-h {
          display: none; }

        @include media-desktop {
          display: block; } } }

    &-text {
      @include fs14; } }

  &__buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    width: 100%;

    @include media-tablet-h {
      align-items: flex-start; }

    .btn--medium {
      min-width: auto; }

    .btn {
      @include h24;
      width: 100%;
      font-weight: 700;

      @include media-tablet-h {
        width: 18.9375rem; } }

    &-text {
      @include fs14;
      align-self: start; }

    &--archive {
      .btn {
        @include media-tablet-h {
          min-width: auto;
          width: 11.3125rem; } } } } }

.forum-news {
  max-height: 37.8125em;
  padding: 1.25em 0.625em 1.25em 1.25em;
  color: $color-black;
  background: #fff;
  overflow: hidden;

  @include media-tablet {
    max-height: 31.4375em;
    padding: 2em 1.875em 1.875em; }

  @include media-tablet-h {
    position: absolute;
    top: 0;
    right: 0;
    width: 507px;
    flex: 0 0 31.25em;
    height: 100%;
    max-height: none;
    padding: 1.5em 1.875em 1.5em 1.5em; }

  @include media-desktop {
    padding: 2.5em;
    width: 500px; }

  @include media-desktop-1440 {
    flex-basis: 33.75em;
    width: 540px; }

  @include media-desktop-1600 {
    width: 40%; }

  &__title {
    @include h24;
    margin-bottom: 1.25rem;

    @include media-tablet {
      margin-bottom: 1.5rem; } }

  &__list {
    max-height: 33.4375em;
    padding-right: 0.625em;
    overflow-y: auto;
    scrollbar-color: rgba(#191919, 0.15) rgba(#191919, 0.05);
    scrollbar-width: thin;

    @include media-tablet {
      max-height: 25.6875em;
      padding-right: 1.875em; }

    @include media-tablet-h {
      height: 100%;
      max-height: none;
      padding-right: 3em; }

    @include media-desktop {
      padding-right: 4em; }

    @include media-desktop-1600 {
      padding-right: 15.1875em; }

    @include media-desktop-1920 {
      padding-right: 40%; }

    &::-webkit-scrollbar {
      width: 0.25rem;
      margin-right: 5px; }

    &::-webkit-scrollbar-track {
      background-color: rgba(#191919, 0.05); }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(#191919, 0.15);
      border-radius: 1.25rem; } }

  &__item {
    display: flex;
    flex-direction: column;
    row-gap: 0.75em;
    margin-bottom: 1.25em;
    padding-bottom: 1.25em;

    @include media-tablet {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between; }

    &:not(:last-child) {
      border-bottom: 1px solid #d9d9de; }

    &-title {
      @include fs14;
      font-weight: 800; }

    &-date-block {
      display: flex;
      align-items: center;
      column-gap: 0.75em;

      hr {
        height: 0.875em;
        width: 1px;
        margin: 0;
        background: #000222;
        border: none; } }

    &-time {
      position: relative;
      @include fs14;

      span {
        position: relative;
        top: -0.4em;
        font-size: 0.6875em;
        line-height: 1.35; } }

    &-date {
      @include fs14; }

    &-text {
      @include fs14;

      @include media-tablet {
        flex-basis: 100%; } } } }
