.contacts-valid {
  display: grid;
  grid-template-columns: 1fr 10.375em;
  column-gap: 1em;
  padding: 2em;
  border-radius: 0.75em;
  border: 1px solid $color-red;
  background: $white;

  @include media-desktop-1600 {
    padding: 2.5em; }

  &__content {
    display: flex;
    flex-direction: column;
    row-gap: 1.5em; }

  &__title {
    @include h24;
    color: $color-red; }

  &__desc {
    @include p18; }

  &__button-block {
    display: flex;
    align-items: center;
    padding: 0 1.5rem;

    .btn {
      min-width: 0;
      width: 100%; } } }
