.netw-card {
  position: relative;

  display: grid;
  grid-template-rows: 15rem 1fr;

  border-radius: 12px;
  background-color: #ffffff;
  overflow: hidden;

  &__bg {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center; } }

  &__body {
    display: grid;
    row-gap: 24px;
    padding: 1.5rem;
    margin-top: -6.25rem;
    grid-template-rows: repeat(2, max-content) 1fr; }

  &__logo {
    display: block;
    width: 7.5rem;
    height: 7.5rem;
    padding: 0.875rem 0.5625rem;

    border-radius: 0.25rem;
    border: 1px solid $grey-e5;
    background-color: #ffffff;

    img {
      width: 100%;
      height: 100%;

      object-fit: contain;
      object-position: center; } }

  &__content {
    display: grid;
    row-gap: 12px; }

  &__title {
    @include reset;
    @include fs18;

    font-weight: 800;

    &:hover {
      color: $color-red;
      text-decoration: underline; } }

  &__desc {
    @include reset;
    @include p11; }

  &__more-link {
    @include fs14;

    color: $color-red;
    text-decoration: underline; }

  &__footer {
    display: grid;
    row-gap: 24px;
    margin-top: auto; }

  &__contacts {
    display: grid;
    row-gap: 0.5rem;
    column-gap: 0.5rem;
    align-items: center;

    &-title {
      @include reset;
      @include fs11;

      color: $grey-88; }

    &-list {
      @include lreset;

      display: flex;
      flex-wrap: wrap;
      column-gap: 0.5rem;
      align-items: center; }

    &-item {
      width: 2.25rem;
      height: 2.25rem;

      border-radius: 50%;
      overflow: hidden;
      background-color: $grey-c0;

      img {
        width: 100%;
        height: 100%;

        object-fit: cover;
        object-position: center; }

      &--counter {
        @include h24;

        width: auto;
        height: auto;

        color: $grey-c0;

        background-color: transparent; } } }

  &__buttons {
    display: grid;
    row-gap: 0.5rem;

    .btn {
      @include fs16;
      min-height: 2.625rem; } }

  // * GENERAL

  &--general {
    @include media-tablet {
      grid-column: span 2;
      grid-template-columns: 19.1875rem 1fr;
      grid-template-rows: 1fr; }

    @include media-tablet-h {
      grid-column: span 6;
      grid-template-columns: 1fr 34.6875rem; }

    @include media-desktop-1440 {
      grid-template-columns: 1fr 44.6875rem; }

    .netw-card {
      &__body {
        @include media-tablet {
          margin-top: 0;
          padding: 2rem; } }

      &__title {
        @include media-tablet {
          @include h24; } }

      &__desc {
        @include media-tablet {
          @include p14; } }

      &__more-link {
        @include media-tablet {
          @include fs14; } }

      &__footer {
        @include media-tablet-h {
          grid-template-columns: 1fr 11.125rem;
          column-gap: 1.5rem;
          align-items: center; }

        @include media-desktop-1440 {
          grid-template-columns: 1fr max-content; } }

      &__contacts {
        @include media-tablet-h {
          grid-column: 1; } }

      &__buttons {
        @include media-tablet-h {
          grid-column: 2; }

        @include media-desktop-1440 {
          grid-template-columns: repeat(2, max-content);
          column-gap: 0.5rem; } } } }

  // * PLATINUM

  &--platinum {
    grid-template-rows: 10rem 1fr;

    @include media-tablet {
      grid-template-columns: 9.75rem 1fr;
      grid-template-rows: 1fr;
      grid-column: span 2; }

    @include media-tablet-h {
      grid-column: span 3; }

    @include media-tablet-h {
      grid-template-columns: 12.3125rem 1fr; }

    @include media-desktop {
      grid-template-columns: 9.75rem 1fr; }

    @include media-desktop-1440 {
      grid-template-columns: 13.6875rem;
      1fr {}
      grid-column: span 2; }

    @include media-desktop-1600 {
      grid-template-columns: 18rem 1fr; }

    .netw-card {
      &__body {
        margin-top: -4rem;
        gap: 36px;

        @include media-tablet {
          margin-top: 0;
          grid-template-rows: max-content 1fr; }

        @include media-desktop-1440 {
          grid-column: 2; } }

      &__title {
        @include fs16;

        @include media-tablet {
          @include h24; }

        @include media-tablet-h {
          @include fs14; }

        @include media-desktop-1440 {
          @include fs18; } }

      &__desc {
        @include media-desktop-1440 {
          @include p14; } }

      &__logo {
        @include media-tablet {
          position: absolute;
          bottom: 1.5rem;
          left: 1.5rem;

          width: 6.75rem;
          height: 6.75rem; }

        @include media-tablet-h {
          left: 2.8125rem; }

        @include media-desktop {
          left: 1.125rem;

          width: 7.5rem;
          height: 7.5rem; }

        @include media-desktop-1440 {
          left: 3.0625rem; }

        @include media-desktop-1600 {
          left: 5.4375rem; } }

      &__more-link {
        @include media-tablet {
          @include fs14; } }

      &__contacts {
        grid-template-columns: max-content 1fr;
        column-gap: 0.25rem;

        &-item {
          width: 24px;
          height: 24px; } }

      &__buttons {
        @include media-tablet {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          column-gap: 0.5rem; }

        @include media-tablet-h {
          grid-template-columns: 1fr; } } } }

  // * GOLD

  &--gold {
    grid-template-rows: 7.5rem 1fr;

    @include media-tablet-h {
      grid-column: span 2; }

    @include media-desktop-1440 {
      grid-column: span 1; }

    .netw-card {
      &__body {
        gap: 16px;
        margin-top: -5.25rem; }

      &__content {
        gap: 16px; }

      &__title {
        @include fs14; }

      &__contacts {
        grid-template-columns: max-content 1fr;
        column-gap: 0.25rem;


        &-item {
          width: 1.5rem;
          height: 1.5rem; } } } }

  // * SILVER

  &--silver {
    grid-template-rows: 1fr;

    @include media-tablet-h {
      grid-column: span 2; }

    @include media-desktop-1440 {
      grid-column: span 1; }

    .netw-card {
      &__body {
        margin-top: 0; }

      &__title {
        @include fs14; } } }

  // * BRONZE

  &--bronze {
    grid-template-rows: 1fr;

    border: 1px solid $grey-c0;
    background-color: $grey-f5;

    @include media-tablet-h {
      grid-column: span 2; }

    @include media-desktop-1440 {
      grid-column: span 1; }

    .netw-card {
      &__body {
        grid-template-rows: auto 1fr;
        margin-top: 0; }

      &__title {
        @include fs14; } } }

  // * AGENT

  &--agent {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;

    @include media-tablet-h {
      grid-column: span 2; }

    @include media-desktop-1440 {
      grid-column: span 1; }

    .netw-card {
      &__body {
        margin-top: 0; }

      &__title {
        @include fs18;
        font-weight: 700;
        text-decoration: underline;

        &:hover {
          color: $color-red;
          text-decoration: none; } }

      &__logo {
        padding: 0;
        width: 5rem;
        height: 5rem;

        border: none;
        border-radius: 50%;
        overflow: hidden;

        img {
          object-fit: cover; } }

      &__title {
        @include fs18; }

      &__desc {
        @include fs11; }

      &__contacts {
        display: grid;
        grid-template-columns: auto 1fr;
        column-gap: 0.5rem;

        &-item {
          width: 4.25rem;
          height: 2.5rem;
          padding: 0.3125rem 0.375rem;

          border-radius: 0;
          background: #ffffff;

          img {
            width: 100%;
            height: 100%;

            object-fit: contain;
            object-position: center; } } } } } }
