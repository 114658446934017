.userbar {
  position: sticky;
  top: 3.625em;
  display: flex;
  flex-direction: column;
  row-gap: 1.25em;
  padding: 2.5em 2em;
  color: $dark-grey;

  hr {
    @include reset;
    height: 0.0625em;
    border: none;
    background-color: $grey-c0; }

  &__avatar {
    position: relative;
    margin: 0 auto 1em;
    display: flex;
    width: min-content;
    overflow: hidden;

    &-img {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 7.5em;
      height: 7.5em;
      border-radius: 50%;
      background: $grey-c4;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        border-radius: 50%;
        overflow: hidden; } }

    &-imgDefault {
      width: 40px !important;
      height: auto !important; }

    &-changer {
      position: absolute;
      bottom: 0;
      right: 0;
      background: $grey-e5;
      width: 38px;
      height: 38px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;

      &:hover {
        opacity: 0.8; }

      input {
        position: absolute;
        width: 38px;
        height: 38px;
        opacity: 0; }

      img {
        width: 16px; } } }

  &__name {
    @include fs18;
    font-weight: 800;
    margin-bottom: 0.25rem; }

  &__position {
    @include fs11;
    color: $grey-88; }

  &__company {
    display: flex;
    flex-direction: column;
    row-gap: 0.75em;

    &-inner {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      &--notAdded {
        .userbar__company {
          &-img {
            border: 1px dashed $grey-c0;
            border-radius: 0; }

          &-add {
            @extend .main-nav__dropdown-company-btn;
            margin-top: 0.5em;
            text-align: center; } } } }

    &-title {
      @include fs11;
      color: $grey-88; }

    &-img {
      height: 5em;
      padding: 0.75em;
      border: 0.0625em solid $grey-c0;
      border-radius: 0.25em;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center; } }

    &-name {
      @include fs14;
      text-decoration: underline;

      &:hover {
        text-decoration: none; } } }

  &__time {
    @include fs14;
    color: $grey-c0; }

  &__support {
    display: grid;
    row-gap: 0.25em;
    color: $grey-c0;

    p {
      @include reset; }

    & > * {
      @include p14; }

    a {
      color: $red; } } }
