.cabinet-stand {
  display: flex;
  flex-direction: column;
  row-gap: 2em;

  &__main {
    display: grid;
    grid-template-columns: 1fr 35.375em;
    column-gap: 2em;
    align-items: start; }

  &__col {
    display: flex;
    flex-direction: column;
    row-gap: 2em; }

  &__form {
    display: flex;
    flex-direction: column;
    row-gap: 2.5em;
    padding: 2.5em;
    border-radius: 0.75em;
    background: $white;

    hr {
      @include reset;
      height: 1px;
      border: none;
      background: $grey-e5; }

    &-header {
      display: flex;
      justify-content: space-between; }

    &-title {
      @include h24; }

    &-edit {
      @include fs14;
      color: $red; }

    &-group {
      display: flex;
      flex-direction: column;
      row-gap: 1.5em;

      &-title {
        @include h18; }

      &-desc {
        @include fs14;
        color: $grey-88; } }

    &-about {
      @include p18; }

    &-file {
      @include fs14;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 64px;
      padding: 0.75rem;
      border-radius: 0.25rem;
      border: 1px dashed $grey-c0;
      cursor: pointer;
      color: $grey-c0; }

    &-footer {
      display: flex;
      justify-content: space-between;
      column-gap: 1em;

      .btn {
        width: calc(50% - 0.5em); } } }

  &__contacts {
    display: flex;
    justify-content: space-between;
    padding: 2.5em;
    border-radius: 0.75em;
    background: $white;

    &-title {
      @include h18; }

    &-link {
      height: auto; } }

  // right
  &__netw {
    display: flex;
    flex-direction: column;
    row-gap: 2.5em;
    padding: 2.5em;
    border-radius: 0.75em;
    background: $white;

    &-title {
      @include h24;

      span {
        color: $red; } }

    &-desc {
      @include fs16; } } }
