.speakers-slider {
  overflow: visible;
  padding-bottom: 106px !important;

  @include media-tablet-h {
    padding-bottom: 100px !important; }

  @include media-desktop-1440 {
    padding-bottom: 120px !important; }

  @include media-desktop-1600 {
    padding-bottom: 140px !important; }

  &__photo {
    object-position: 0 20%;

    @include media-tablet {
      object-position: 0 10%; }

    @include media-tablet-h {
      object-position: 0 0; } }

  .swiper-pagination-bullets {
    bottom: 59px !important;
    display: flex !important;
    justify-content: center !important;
    gap: 0.75rem !important;

    @include media-tablet {
      bottom: 63px !important; }

    .swiper-pagination-bullet {
      width: 0.625rem !important;
      height: 0.625rem !important;
      margin: 0 !important;
      background: $grey-c0 !important;
      opacity: 1 !important;

      @include media-tablet-h {
        display: none; }

      &-active {
        background: $red !important; } } } }
