//576px
@mixin media-tablet-x {
  @media (min-width: $tablet-width-x) {
    @content; } }

//768px
@mixin media-tablet {
  @media (min-width: $tablet-width) {
    @content; } }

//1024px
@mixin media-tablet-h {
  @media (min-width: $tablet-width-h) {
    @content; } }

//1280px
@mixin media-desktop {
  @media (min-width: $desktop-width) {
    @content; } }

@mixin media-desktop-1280 {
  @media (min-width: $desktop-width-1280) {
    @content; } }

@mixin media-desktop-1440 {
  @media (min-width: $desktop-width-1440) {
    @content; } }

@mixin media-desktop-1600 {
  @media (min-width: $desktop-width-1600) {
    @content; } }

@mixin media-desktop-1920 {
  @media (min-width: $desktop-width-1920) {
    @content; } }

@mixin min-w($width) {
  @media (min-width: $width) {
    @content; } }

@mixin max-w($width) {
  @media (max-width: $width) {
    @content; } }

@mixin retina-img {
  @media (min-resolution: $retina-dpi), (min-resolution: $retina-dppx) {
    @content; } }
