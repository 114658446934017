.react-tel-input.phone-input {

  width: 100%;

  .form-control {
    @include p14;

    width: 100%;
    height: 3rem;
    border-radius: 0.25rem;
    border: 1px solid #e5e5e5; }

  .flag-dropdown {
    top: 2px;
    bottom: 2px;
    left: 1px;
    border: none;
    border-radius: 0.25rem 0 0 0.25rem;
    background-color: transparent;
    border-right: 1px solid #e5e5e5;

    &.open {
      border-radius: 0.25rem 0 0 0.25rem;

      .selected-flag {
        background-color: transparent; } }

    .selected-flag {
      background-color: transparent;

      &:hover {
        background-color: transparent; } } } }

.react-tel-input .form-control:focus {
  outline: auto; }
