.partners {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: white;
  justify-content: center;

  @include media-tablet {
    padding-top: 46px;
    padding-bottom: 46px; }

  @include media-tablet-h {
    padding-top: 37px;
    padding-bottom: 37px; }

  @include media-desktop {
    padding-top: 43px;
    padding-bottom: 43px; }

  &__wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    width: 100%;

    @include media-tablet {
      row-gap: 2rem;
      flex-direction: row;
      justify-content: space-between; }

    &--two-item {
      @include media-desktop-1600 {
        grid-template-columns: repeat(2, 1fr); }

      .partners__group--org {
        @include media-tablet-h {
          grid-row: auto; } } } }

  &__group {
    display: flex;
    align-items: center;

    &--org {
      column-gap: 10px;


      @include media-tablet {
        column-gap: 1.5rem; }

      @include media-tablet-h {
        grid-row: 1/3; }

      @include media-desktop-1600 {
        grid-row: auto; } }

    &--sup {
      flex-direction: column;
      row-gap: 1.25rem;

      @include media-tablet {
        flex-direction: row;
        column-gap: 1.5rem; }

      @include media-tablet-h {
        justify-content: flex-end; } }

    &--part {
      flex-direction: column;
      row-gap: 1.25rem;

      @include media-tablet {
        flex-direction: row;
        column-gap: 1.5rem; }

      @include media-tablet-h {
        justify-content: flex-end; } } }

  &__title {
    @include fs14;
    color: $grey;
    line-height: 1;

    @include media-tablet-h {
      @include fs16;
      line-height: 1; } }

  &__list {
    @include lreset;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;

    @include media-tablet {
      gap: 1.25rem; }

    @include media-tablet-h {
      width: initial; } }

  &__item {
    max-width: 90px;
    max-height: 90px;

    @include media-tablet-h {
      max-width: 100px;
      max-height: 100px; }

    @include media-desktop {
      max-width: 150px;
      max-height: 150px; }

    img {
      width: 80%;
      height: 100%;
      object-fit: contain;
      object-position: center;
      transform: translateZ(0); } } }
