.payment {
  background: $grey-f5;
  color: $dark-grey;

  @include media-desktop {
    min-height: 54.375em;
    align-items: center; }

  &__cont {
    @include max-w(575px) {
      padding: 0; } }

  &__wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 2em;
    margin: 4em 0;
    padding: 1.25em;
    border-radius: 0.75em;
    background: $white;

    @include media-tablet {
      padding: 2.5em;
      row-gap: 4em; }

    @include media-tablet-h {
      padding: 5em; }

    &--method {
      max-width: 35.375em;
      margin: 4em auto;

      @include media-tablet {
        row-gap: 2em; }

      @include media-desktop {
        margin: 7.5em auto; } } }

  &__header {
    display: flex;
    flex-direction: column;
    row-gap: 2em;

    @include media-desktop {
      flex-direction: row;
      justify-content: space-between;
      column-gap: 3.75em; }

    &-link {
      font-size: 0.875rem;
      font-weight: 700;

      @include media-tablet {
        font-size: 1rem; } } }

  &__title {
    @include reset;
    @include h24;
    display: flex;
    flex-direction: column;
    row-gap: 0.75rem;

    @include media-tablet {
      @include h36; }

    @include media-desktop {
      max-width: 30.9375rem; }

    span {
      font-size: 1.5rem;
      font-weight: 400;
      line-height: 1.25; } }

  &__desc {
    @include fs14;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 0.75rem;

    @include media-tablet {
      @include fs16; }

    @include media-desktop {
      width: calc(50% - 1rem); }

    @include media-desktop-1600 {
      width: auto;
      max-width: 39em; }

    p {
      @include reset; } }

  &__block {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.5em;
    padding: 1.25em;
    border-radius: 1.25em;
    background: $white;

    @include media-tablet-x {
      gap: 2em;
      padding: 2.5em; }

    &-header {
      display: flex;
      align-items: center;
      column-gap: 1.25em; }

    &-number {
      @include h16;
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 0 0 2.125rem;
      width: 2.125rem;
      height: 2.125rem;
      padding: 0.5rem;
      border-radius: 50%;
      background: $grey-d5;
      color: $grey-88;

      @include media-tablet-x {
        @include h18;
        flex-basis: 2.4375rem;
        width: 2.4375rem;
        height: 2.4375rem; } }

    &-title {
      @include h16;

      @include media-tablet-x {
        @include h18; } }

    &-body {
      display: flex;
      flex-direction: column;
      gap: 2em; }

    &-description {
      @include fs16;
      display: flex;
      flex-direction: column;
      row-gap: 0.75rem;

      p {
        @include reset; } }

    &-btn {
      text-align: center; } }

  &__form {
    display: flex;
    flex-direction: column;
    gap: 1.25em;

    @include media-tablet-h {
      gap: 2em; }

    &-divider {
      width: 100%;
      height: 1px;
      background: $grey-e5; }

    &--horizontal {
      @include media-desktop {
        flex-direction: row; } } }

  &__radio {
    @include media-desktop {
      width: calc(33.3% - 1.375em); }

    &-group {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      gap: 1.125em;

      @include media-tablet {
        flex-direction: row;
        justify-content: space-between; } }

    &-description {
      @include fs14;
      width: 100%;
      color: $grey-88; }

    &-label {
      cursor: pointer; }

    input:checked + .payment__radio-label {
      .payment__tariff {
        &::before {
          background-image: url("@public/images/tariffs/radio-active.svg"); } } }

    &--method {
      @include media-desktop {
        width: auto; }

      .payment__radio-label {
        @include fs16;
        display: flex;
        align-items: center;
        column-gap: 0.625em;
        color: $dark-grey;
        letter-spacing: -0.16px;
        cursor: pointer;

        &::before {
          content: "";
          flex: 0 0 1.25rem;
          width: 1.25rem;
          height: 1.25rem;
          background: url("@public/images/tariffs/radio.svg") no-repeat;
          transition: all ease 0.3s; } }

      input:checked + .payment__radio-label {
        &::before {
          background-image: url("@public/images/tariffs/radio-active.svg"); } } }

    &--active {
      .payment__tariff {
        border-color: $red; } }

    &--hide {
      display: none; } }

  &__promo {
    display: grid;
    grid-template-areas: "input button" "error error";
    grid-template-columns: 1fr 6.5em;
    gap: 0.75em 0;

    @include media-tablet {
      grid-template-columns: 1fr 7.5em;
      gap: 0.75em; }

    .payment__input {
      grid-area: input; }

    &-btn {
      grid-area: button;
      min-width: auto; }

    .payment__error {
      grid-area: error; } }

  &__input {
    @include fs16;
    height: 2.8125rem;
    padding: 0 0.6875rem;
    border-radius: 0.25rem;

    &::-webkit-input-placeholder {
      color: $grey-88; }

    &::-moz-placeholder {
      color: $grey-88; } }

  &__total {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 0.75em 0.375em;

    &-title {
      @include fs14;
      width: 100%;
      color: $grey-88; }

    &-value {
      @include h24; }

    &-promo {
      @include fs16;
      color: $grey-88; } }

  &__bottom {
    display: flex;
    flex-direction: column;
    row-gap: 0.75em; }

  &__error {
    @include fs14;
    width: 100%;
    color: $red;

    &-link {
      font-size: 0.85rem; } }

  &__tariff {
    @include media-tablet {
      padding: 1.875em 1.125em; }

    @include media-desktop {
      width: calc(33.3% - 1.375em); }

    @include media-desktop-1440 {
      padding: 1.875em; }

    .tariff__tag {
      @include media-tablet {
        top: 1.875rem;
        right: 1.125rem; }

      @include media-desktop-1440 {
        right: 1.875rem; } }

    .tariff__old-price {
      font-size: 1rem;

      span {
        font-size: 1.375rem;

        @include media-tablet {
          font-size: 1.5rem; } } }

    .tariff__features-item {
      @include fs14; } }

  &__method {
    &-title {
      @include h24;
      margin: 0;

      @include media-tablet-x {
        @include h28; } } }

  &__schedule {
    display: flex;
    display: none;
    flex-direction: column;
    // row-gap: 0.875em
    width: 100%;
    padding: 2em;
    border-radius: 0.75em;
    border: 1px solid $grey-e5;

    &-header {
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 2em;

      @include media-desktop {
        flex-direction: row;
        justify-content: space-between;
        column-gap: 3.75em; } }

    &-title {
      @include h24; }

    &-desc {}

    &-item {
      &-header {
        @include fs14;
        position: relative;
        display: flex;
        height: 2.1875rem;
        column-gap: 0.25rem;
        padding: 0.5rem 1rem;
        border-radius: 0.5rem 0.5rem 0 0;
        background-color: $red;
        color: $white;
        font-weight: 700;
        overflow: hidden;

        @include media-tablet-x {
          padding: 0.5rem 1.5rem; }

        span {
          display: none;

          @include media-tablet-x {
            display: block; } } }

      &-body {
        display: flex;
        flex-direction: column;
        gap: 0.75em;
        padding: 1em;
        border: 1px solid $grey-c0;
        border-radius: 0 0 0.5em 0.5em;

        @include media-tablet-x {
          padding: 1.5em; } }

      &-info {
        display: flex;
        justify-content: space-between; }

      &-date {
        @include fs16;
        font-weight: 800; }

      &-flag {
        @include fs11;
        font-weight: 800; }

      &-company {
        @include fs11;
        color: $red; }

      &-desc {
        @include fs14; }

      &-btn {
        margin: 0.5rem 0 0 auto;

        a {
          display: block;

          &:hover {
            svg {
              fill: $red;

              rect {
                stroke: $white; }

              path {
                fill: $white; } } } }

        svg {
          display: block; } }

      &--mald {
        .payment__schedule-item-header {
          &::after {
            position: absolute;
            content: '';
            top: 0;
            right: 0;
            width: 4.5rem;
            height: 2.1875rem;
            background: $white url(@public/images/schedule/schedule-pattern-2.png) left center / 2.875rem auto;
            border-top-left-radius: 0.5rem;

            @include media-tablet-x {
              width: 8.5rem;
              background-size: 3.875rem auto; } } } }

      &--sey {
        .payment__schedule-item-header {
          &::after {
            position: absolute;
            content: '';
            top: 0;
            right: 0;
            width: 4.5rem;
            height: 2.1875rem;
            background: $white url(@public/images/schedule/schedule-sey.svg) center / 1.875rem auto no-repeat;
            border-top-left-radius: 0.5rem;

            @include media-tablet-x {
              width: 8.5rem; } } } }

      &--arab-lux {
        .payment__schedule-item-header {
          &::after {
            position: absolute;
            content: '';
            top: 0;
            right: 0;
            width: 4.5rem;
            height: 2.1875rem;
            background: $white url(@public/images/schedule/schedule-pattern-2.png) left center / 2.875rem auto;
            border-top-left-radius: 0.5rem;

            @include media-tablet-x {
              width: 8.5rem;
              background-size: 3.875rem auto; } } } }

      &--cuba {
        .payment__schedule-item-header {
          &::after {
            position: absolute;
            content: '';
            top: 0;
            right: 0;
            width: 4.5rem;
            height: 2.1875rem;
            background: $white url(@public/images/schedule/schedule-cuba.jpeg) center / 2.75rem auto no-repeat;
            border-top-left-radius: 0.5rem;

            @include media-tablet-x {
              width: 8.5rem; } } } }

      &--bel {
        .payment__schedule-item-header {
          &::after {
            position: absolute;
            content: '';
            top: 0;
            right: 0;
            width: 4.5rem;
            height: 2.1875rem;
            background: url(@public/images/schedule/schedule-bel.svg) left center / cover no-repeat;
            border-top-left-radius: 0.5rem;

            @include media-tablet-x {
              width: 8.5rem; } } } } } } }
