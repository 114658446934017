.main-nav {
  position: sticky;
  z-index: 999;
  left: 0;
  top: 0;
  right: 0;
  background-color: black;
  border-bottom: 1px solid $grey-e5;

  &--gray {
    background-color: #F5F5F5; }

  .cont {
    height: 4.375rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include media-desktop {
      position: relative; } }

  &__wrapper {
    position: fixed;
    right: 0;
    top: 0;
    transform: translateX(100%);

    @include media-tablet {
      position: relative;
      transform: translateX(0); } }

  &__left {
    display: grid;
    grid-template-columns: 1fr auto auto;
    align-items: center;

    @include media-tablet {
      column-gap: 1.5em; }

    hr {
      @include reset;
      display: none;
      width: 1px;
      height: 100%;
      border: none;
      background-color: $dark-grey;

      @include media-tablet-h {
        display: block; } } }

  &__logo {
    @include reset;
    display: flex;
    align-items: center;
    gap: 1rem;

    p {
      margin: 0; }

    &-description {
      display: flex;
      flex-direction: column;
      gap: 2px;

      &-top {
        @include fs20;
        font-weight: 700;
        line-height: 1.25rem; }

      &-bottom {
        @include fs12;
        font-weight: 600;
        line-height: 0.75rem; } }

    @include media-desktop {
      flex-grow: 0; }

    &-plus {
      @include fs18;
      display: none;
      color: $white;

      @include media-tablet-h {
        display: block; } }

    &-link {
      @include fs18;
      display: none;
      color: $white;
      font-weight: 800;

      @include media-tablet-h {
        display: block; } }

    img {
      display: block;
      width: 4.313rem;
      height: 2.125rem; } }

  &__menu {
    @include lreset;
    display: none;

    @include media-tablet-h {
      display: grid;
      column-gap: 10px;
      grid-auto-flow: column; }

    @include media-desktop-1440 {
      column-gap: 32px; }

    &-item {
      a {
        @include fs14;
        color: $dark-grey; } }

    &-hamburger {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 1.5em;
      height: 1.5em;

      svg {
        width: 1em;
        height: 0.75em; }

      &::before,
      &::after {
        content: "";
        position: absolute; }

      @include media-tablet-h {
        display: none; } } }

  &__counter {
    @include fs14;
    display: none;
    flex-grow: 1;
    color: $dark-grey;
    text-align: center;
    opacity: 0.7;

    @include media-desktop-1280 {
      display: block; }

    span {
      font-weight: 800; } }

  &__user-nav {
    @include reset;
    margin: 0 0.625em 0 auto;

    @include media-tablet {
      display: flex;
      align-items: center;
      column-gap: 1.25em;
      margin-right: 0; }

    @include media-tablet-h {
      column-gap: 1.5em; }

    @include media-desktop {
      column-gap: 1.25em; }

    &--unlogged {
      display: flex;
      align-items: center;
      column-gap: 10px;
      margin-right: 0;

      @include media-tablet {
        column-gap: 1em; } }

    &-item {
      list-style-type: none;

      a {
        @include fs16;
        color: $white;

        @include media-desktop {
          @include fs14; } }

      &--meetings {
        display: none;

        @include media-desktop-1440 {
          // display: block
          margin-right: 1.5rem; }

        @include media-desktop-1600 {
          margin-right: 2.5rem; }

        a {
          display: flex;
          align-items: center;
          border: 1px solid $red;
          border-radius: 0.25rem;
          overflow: hidden;

          span {
            &:nth-child(1) {
              padding: 0.2rem 0.5rem; }

            &:nth-child(2) {
              display: flex;
              align-items: center;
              padding: 0.2rem 0.5rem 0.2rem 0.25rem;
              border-radius: 0.25rem;
              margin: 0.2rem 0.4rem;
              font-size: 0.7857em;
              font-weight: 800;
              background-color: $red; } } } }

      &--tariff {
        a {
          display: flex;
          align-items: center;
          column-gap: 0.5rem;
          padding: 0.25rem 0.375rem;
          border: 1px solid $red;
          border-radius: 0.25rem;
          @include fs14;
          overflow: hidden;

          @include media-tablet {
            padding: 0.25rem 0.5rem; }

          span {
            &:nth-child(2) {
              display: flex;
              align-items: center;
              padding: 0.125rem 0.25rem;
              border-radius: 0.25rem;
              background-color: $red;
              font-size: 0.5rem;
              font-weight: 700;

              @include media-tablet {
                font-size: 0.6875rem; } } } } }

      &--msgs {
        display: none;

        @include media-desktop {
          display: block; }

        a {
          display: flex;
          align-items: center;
          justify-content: center; }

        span {
          @include fs11;
          margin-left: 0.571em;
          padding: 0.1818em 0.3636em;
          font-weight: 800;
          border-radius: 0.3636em;
          background-color: $red; } }

      &--name {
        display: none;

        @include media-tablet {
          display: block; }

        a {
          @include fs14;
          display: flex;
          align-items: center;
          column-gap: 6.3px;
          font-weight: 800; } }

      &--reg {
        a {
          @include fs14;
          display: block;
          padding: 0.35rem 1.25rem;
          border-radius: 4px;
          background: $red;
          color: $white;
          font-weight: 700;
          width: 115.5px;
          height: 36px;
          text-align: center;
          padding: 9px 0;

          @include media-tablet {
            width: 132px; } } }


      &--login {
        a {
          @include fs14;
          display: block;
          padding: 0.35rem 1.25rem;
          background-color: $white;
          border-radius: 4px;
          color: $dark-grey;
          font-weight: 700;
          width: 115.5px;
          height: 36px;
          text-align: center;
          padding: 9px 0;

          @include media-tablet {
            width: 132px; } } } } }

  &__dropdown {
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: 9999;
    background-color: $grey-f5;
    right: -376px;
    width: 290px;
    padding: 20px 20px;
    box-shadow: 0 0.25em 2.5em rgba(0, 0, 0, 0.15);
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s ease-out, transform 0.3s ease-out, visibility 0.3s ease-out;

    @include media-tablet {
      bottom: auto;
      right: -519px;
      width: 400px;
      padding: 20px 40px;
      height: 100vh; }

    &--open {
      visibility: visible;
      opacity: 1;
      transform: translateX(-130%); }

    hr {
      height: 0.0625em;
      width: 100%;
      margin-top: 1em;
      margin-bottom: 1em;
      border: none;
      background-color: $grey-c0; }

    &-close {
      position: absolute;
      width: 18px;
      height: 18px;
      transform: rotate(45deg);
      cursor: pointer;


      &::before,
      &::after {
        content: "";
        position: absolute;
        background-color: $dark-grey; }

      &::before {
        top: 50%;
        transform: translateY(-50%);
        width: 18px;
        height: 2px; }

      &::after {
        left: 50%;
        transform: translateX(-50%);
        height: 18px;
        width: 2px; } }

    &-name {
      @include reset;
      @include fs16;
      width: 12em;
      font-weight: 800;

      @include media-desktop {
        width: 100%;
        padding-right: 2em; } }

    &-list {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 15px;

      & > .main-nav__dropdown-item {
        &:last-child {
          margin-bottom: 0; } } }

    &-sublist {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      &-btn {
        display: none; }

      &--closed {
        border: 1px dashed $grey-c0;
        padding: 0.75em 5.125em 0.75em 0.75em;
        margin-bottom: 0.75em;
        width: 100%;

        .main-nav__dropdown-item {
          color: $grey-c0;

          &:last-of-type {
            margin-bottom: 0; }

          &--msg {
            span {
              display: none; } } }

        .main-nav__dropdown-sublist-btn {
          position: absolute;
          top: 50%;
          right: 1.5em;
          transform: translateY(-50%);
          display: block;
          width: 2.125em;
          height: 2.125em;
          border-radius: 0.25em;
          border: 1px solid $grey-c0;
          background-image: url("../images/lock.svg");
          background-repeat: no-repeat;
          background-position: center;
          background-size: 1em 1.125em;

          @include media-desktop {
            width: auto;
            min-width: 8.125em;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-right: 2.125em;
            background-position: right 0.5625em center; }

          &::after {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            right: 2em;
            width: 0.0625em;
            background-color: $grey-c0; }

          span {
            display: none;

            @include media-desktop {
              @include fs14;
              display: block;
              color: $grey-88; } } } }

      &--closed-2 {
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23C0C0C0FF' stroke-width='1' stroke-dasharray='8%2c 8' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
        padding: 0.75em 5.125em 0.75em 0.75em;
        margin-bottom: 0.75em;
        width: 100%;

        .main-nav__dropdown-item {
          color: $grey-c0;

          &:last-of-type {
            margin-bottom: 0; }

          &--msg {
            span {
              display: none; } } }

        .main-nav__dropdown-sublist-btn {
          display: block;
          height: 2.125em;
          width: auto;
          min-width: 8.125em;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 0.857em;
          text-align: center;
          border-radius: 0.25em;
          border: 1px solid $grey-c0;

          @include media-desktop {
            position: absolute;
            top: 50%;
            right: 1.5em;
            transform: translateY(-50%);
            margin-top: 0; }

          span {
            @include fs14;
            color: $grey-88; } } } }

    &-item {
      @include fs14;
      color: $dark-grey;

      &-counter {
        padding: 0.1818em 0.3636em;
        margin-left: 0.5rem;
        color: $white;
        font-size: 0.6875rem;
        font-weight: 700;
        border-radius: 2px;
        background-color: $red; }

      &--msg {
        span {
          @include fs11;
          margin-left: 0.571em;
          padding: 0.1818em 0.3636em;
          font-weight: 800;
          color: $white;
          border-radius: 0.3636em;
          background-color: $red; } }

      &--networking {
        position: relative;
        padding-left: 1rem;
        color: $grey-88;
        font-weight: 700;

        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 0.5rem;
          height: 0.5rem;
          border-radius: 50%;
          background-color: $grey-e5; }

        &-active {
          &::before {
            background-color: $green; } } }

      &--closed {
        width: 2.125rem;
        height: 2.125rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        border: 1px solid $grey-88; } }

    &-company {
      &-title {
        @include reset;
        @include fs14;
        margin-bottom: 0.571em;
        color: $grey-c0;

        a {
          color: $dark-grey; } }

      &-logos {
        display: flex;
        flex-wrap: wrap;
        align-items: center; }

      &-item {
        width: 7.5em;
        height: 5em;
        padding: 0.625em;
        margin-right: 1em;

        img {
          @include contain-img; } }

      &-name {
        @include fs14;
        display: none;
        font-weight: 800;

        @include media-desktop {
          display: block; } }

      &-btn {
        @include fs14;
        display: inline-block;
        margin: 0.3em 0 0.3em;
        padding: 0.571em 1.071em;
        color: $dark-grey;
        border: 1px solid $dark-grey;
        border-radius: 0.286em; }

      &--added {
        .main-nav__dropdown-company {
          &-title {
            display: flex;
            justify-content: space-between;

            a {
              color: $red;
              text-decoration: underline;

              &:hover {
                text-decoration: none; } } }

          &-logos {
            color: $dark-grey; } } }

      &--notAdded {
        .main-nav__dropdown-company {
          &-item {
            display: none;
            position: relative;
            margin-right: 0.375em;

            @include media-desktop {
              display: block; }

            &::before {
              content: "";
              position: absolute;
              left: 0.625em;
              top: 0.625em;
              right: 0.625em;
              bottom: 0.625em;
              border: 1px dashed $grey-c0; } } } } }

    &-exit {
      @include fs14;
      color: $red; } } }
